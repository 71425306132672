.report-style-class {
    height: 69vh;
    margin-top: 2vh;
    margin-left:2px;
    width: 40vw;

}

.front{
    margin-left:40px;
    background-color: #F5F8FC;
}

.frontMain{
    background-color: #F5F8FC;
}

.report-style-class iframe{
    border:0px;
}

.report-style-classProduct{
    height: 69vh;
    margin-top: 2vh;
    margin-left:2px;
    width: 40vw;
}

.report-style-classProduct iframe{
    border: 0px;
}

.invisible{
display:none;
position:absolute;
top:50vh;
right:1px;
left:1px;
background:#FFFFFF;
border:1px solid aquamarine;
border-radius: 0 0 5px 5px;
width:100vw;
height: 24vh;
font-size:16px;
/* transition-duration:160ms; */
text-align:center;
/* &:hover{
  background-color: #ddd;
} */
}


.visible{
        display: grid !important;
        grid-auto-rows: 1fr; 
        grid-template-columns: 0.3fr 1fr 2.4fr 0.3fr; 
        grid-template-rows: 0.2fr 2.6fr 0.2fr; 
        gap: 0px 0px; 
        grid-template-areas: 
            ". . . ."
            ". Products Types ."
            ". . . .";
}

.overview{
    grid-area: Products;
}

.types{
    grid-area:Types;
}

.ProductsText{
font-size: 16px;
font-weight: bold;
}



.ProductsTextBottom{
    font-size: 13px;
}

.OverViewButton{
    margin-top: 35px;
    border: 1px solid #66DDDC;
    background-color: white;
    width: 8vw;
    height: 38px;
}

.Browse{
    font-size: 14px;
    color: #656565;
}


input[type=text], select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  input[type=submit] {
    width: 100%;
    background-color: #2323C8;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  input[type=submit]:hover {
    background-color: #2323C8;
  }
  

.maintext{
    font-weight: 500;
    font-size:45px;
    line-height: 60px;;
    color: #717171;
}

.smalltext{
    font-size: 14px;
    font-weight:500;
    color: #717171;
    @media (min-width: 768px){
        font-size: 20px;
        font-weight:normal;
    }
}

.footerimg{
    height:25px;
    width:auto;
    @media (max-width: 768px){
        height: 35px;
    }
}

.footerfront{
    display: flex;
    flex-direction: column;
}

.footerexpander{
    height: 3vh;
    @media (min-width: 0px) and (max-width: 768px){
        height: 10vh;
    }
}

.HeaderHome{

}

.HeaderSector{

}

.HeaderIndustry{

}

.HeaderCompany{

}

.HeaderBrands{
    
}

.basic-multi-select{
    min-width:200px;
}

.ant-switch-inner {
    background-color:#0099ff;
 }

 .custom-tooltip {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-width: 150px;
  }
  .data-cell {
    position: relative;
}

.tooltiptable {
    display: none;
    position: absolute;
    left: 20%; /* Adjust this as needed */
    top: 80%;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 100;
    width: 200px;
    border-radius:10px;
    box-shadow:  2px 2px 4px 0px #00000040;
    /* Add more styling as per your need */
}

.data-cell:hover .tooltiptable {
    display: block;
}

.tableplayer{
    cursor: pointer;
    color: #0099FE;
}
.tableplayer:hover{
    color: #2323C8;
}

.ValueData{
    color: #0099FE;
}

.carousel.carousel-slider .control-arrow {
    top: 0;
    font-size: 26px;
    bottom: 0;
    margin-top: 50vh;
    padding: 5px;
    height: 100px;
    box-shadow: 0px 0px 5px 0px #222;
    background: #0099FE;
}

.carousel .carousel-status {
    color: #fff;
    font-size: 10px;
    padding: 5px;
    position: absolute;
    right: 0;
    text-shadow: 1px 1px 1px rgba(0,0,0,.9);
    top: 0;
    display: none;
}
